<template>
  <div class="fields-container">
    <hub-text-field
      @keyup="onInputFocus('priceListCode')"
      @blur="onInputBlur"
      :error-messages="(errorMessages && errorMessages.priceListCode) || []"
      name="priceListCode"
      v-model="formData.priceListCode"
      :label="$t('defaultPackagingPricing.fields.priceListCode')"
      persistent-hint
    />
    <hub-text-field
      @keyup="onInputFocus('description')"
      @blur="onInputBlur"
      :error-messages="(errorMessages && errorMessages.description) || []"
      class="modal-form__field"
      name="description"
      v-model="formData.description"
      :label="$t('defaultPackagingPricing.fields.description')"
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('price')"
      @blur="onInputBlur"
      :error-messages="(errorMessages && errorMessages.price) || []"
      name="price"
      v-model="formData.price"
      :label="$t('defaultPackagingPricing.fields.price')"
      persistent-hint
    />

    <hub-text-field
      @keyup="onInputFocus('currency')"
      @blur="onInputBlur"
      :error-messages="(errorMessages && errorMessages.currency) || []"
      class="modal-form__field"
      name="currency"
      v-model="formData.currency"
      :label="$t('defaultPackagingPricing.fields.currency')"
    />
    <div class="break" />
    <RegionDropdown
      ref="regionDropdown"
      :items="operatingRegions"
      @region="updateRegion($event)"
      :error-messages="errorMessages"
      :current-region="formData.originRegion"
    />
  </div>
</template>

<script>
import RegionDropdown from '@/components/forms/RegionDropdown.vue'

export default {
  name: 'InputFields',
  components: { RegionDropdown },
  props: {
    formData: {
      type: Object,
    },
  },
  computed: {
    errorMessages() {
      return this.$store.getters['core/validationErrors']
    },
    operatingRegions() {
      return this.$store.getters['billing/getOriginRegions']?.map(data => ({
        text: data.code,
        value: data.code,
      }))
    },
  },
  methods: {
    onInputFocus(inputName) {
      this.activeField = inputName
    },
    onInputBlur() {
      this.activeField = ''
    },
    updateRegion(e) {
      this.formData.originRegion = e
    },
  },
  watch: {
    formData() {
      this.$refs.regionDropdown.clearData()
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
