<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('defaultPackagingPricing.tablePageTitle') | title" />

    <huboo-table
      id="defaultPackagingPricingTable"
      @filter="handleFilter"
      @row-clicked="onRowSelected"
      @search="handleSearch"
      @update:options="handleUpdateOptions"
      @confirm-delete="onRemove"
      @remove="openConfirmationModal"
      :remove="isAdmin"
      :filters="filters"
      :headers="headers"
      :items="items"
      :loading="loading"
      clickable
      hide-search
      localPagination
      v-bind="options"
    >
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :disabled="loading"
        />
        <v-btn
          v-if="isAdmin"
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="defaultPackagingPricingCreateButton"
        >
          {{ $t('defaultPackagingPricing.createModal.createButton') }}
        </v-btn>
      </template>

      <template #filter>
        <huboo-filters :filters="filters" @filtersClear="onFiltersClear" hide-apply-button>
          <v-col cols="2" sm="6" md="4">
            <v-select
              @change="fetch"
              :label="$t('billingDefaultPricing.fields.region')"
              :items="filteredRegions"
              v-model="availableFilters.selectedRegion"
              dense
              outlined
            />
          </v-col>
        </huboo-filters>
      </template>
    </huboo-table>
    <default-packaging-pricing-edit-modal
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <default-packaging-pricing-create-modal
      v-model="createModal"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />
  </huboo-page>
</template>

<script>
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import VersionedPriceListMixin from '@/mixins/versionedPriceList'
import filtersMixin from '@/mixins/filters'
import { title } from '@/utilities/filters'
import { mapGetters } from 'vuex'
import EditModal from '@/modules/DefaultPackagingPricing/DefaultPackagingPricingEditModal.vue'
import CreateModal from '@/modules/DefaultPackagingPricing/DefaultPackagingPricingCreateModal.vue'
import ExportButton from '@/components/pages/ExportButton.vue'

export default {
  name: 'DefaultPackagingPricing',

  mixins: [ClientMixin, ModalActionsMixin, filtersMixin, VersionedPriceListMixin],

  components: {
    'default-packaging-pricing-edit-modal': EditModal,
    'default-packaging-pricing-create-modal': CreateModal,
    'export-button': ExportButton,
  },

  data: () => ({
    editModal: false,
    createModal: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
    availableFilters: {
      selectedRegion: '',
    },
  }),

  computed: {
    ...mapGetters({
      regions: 'billing/getRegions',
      getDefaultPackagingPricing: 'defaultPackagingPricing/getAll',
    }),

    defaultPackagingPriceLists() {
      const list = this.getDefaultPackagingPricing
      if (Array.isArray(list))
        return list.map(l => ({
          ...l,
          loading: this.isRemoveLoading(l.id),
        }))
      else return []
    },

    defaultPriceListRegions() {
      return this.$store.getters['defaultPackagingPricing/getRegions']
    },

    filteredRegions() {
      if (!this.regions?.data || !this.defaultPriceListRegions) {
        return []
      }

      const regions = this.regions?.data
        .filter(region => this.defaultPriceListRegions.includes(region.code))
        .map(region => {
          return region.code
        })

      regions.unshift(this.$t('billingDefaultPricing.fields.selectAll'))
      return regions
    },

    exportUrl() {
      return this.$store.getters['defaultPackagingPricing/getExportUrl']
    },

    headers() {
      return [
        {
          text: title(this.$t('billing.priceListCode')),
          align: 'start',
          sortable: false,
          value: 'priceListCode',
        },
        {
          text: title(this.$t('common.description')),
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('common.price')),
          sortable: false,
          value: 'price',
        },
        {
          text: title(this.$t('common.currency')),
          sortable: true,
          value: 'currency',
        },
        {
          text: title(this.$t('billing.originRegion')),
          sortable: true,
          value: 'originRegion',
        },
        {
          text: title(this.$t('common.updated')),
          sortable: false,
          value: 'updatedAt',
        },
      ]
    },

    items() {
      return this.defaultPackagingPriceLists.map(item => {
        return {
          ...item,
          price: this.formatPrice(item.price),
          updatedAt: this.formatDate(item.updatedAt),
        }
      })
    },

    meta() {
      return this.$store.getters['defaultPackagingPricing/getMeta'] || { total: this.items.length }
    },

    url() {
      return '/billing-default-price-list/'
    },

    dataReady() {
      return !this.loading && this.getSelectedPriceListVersionId
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
    getSelectedPriceListVersionId() {
      this.fetch()
    },
  },

  created() {
    this.$store.dispatch('billing/fetchRegions')
  },

  methods: {
    fetch() {
      if (this.dataReady) {
        const { options, orderBy, search } = this
        const selectedRegion = this.availableFilters.selectedRegion
        const limit = 1000
        const { page } = options
        const params = {
          // Pagination
          itemsPerPage: limit,
          page: page || undefined,
          orderBy: orderBy || undefined,

          // Filters
          priceListVersion: this.getSelectedPriceListVersionId,
          query: search || undefined,
          region:
            selectedRegion !== this.$t('billingDefaultPricing.fields.selectAll')
              ? selectedRegion
              : undefined,
        }

        return this.$store.dispatch('defaultPackagingPricing/fetchCollection', { params })
      }
    },

    onFiltersClear() {
      this.clearFilters(this.availableFilters)
    },

    onRemove() {
      this.$store.dispatch('defaultPackagingPricing/delete', this.selected.id).finally(() => {
        this.fetch()
        this.selected = ''
      })
    },

    isRemoveLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.url + id,
        method: 'DELETE',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.regionSelect {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0.25rem;
}
</style>
